/*******************************
         Site Overrides
*******************************/

a {
	transition: color 0.3s;
}

body.has_hover a:hover {
  color: @pink;
}