/*******************************
        User Overrides
*******************************/

/**
 * Default dropdown marks border top width as 0px !important - we might want to
 * display a border (specifically, when we make the width of menu larger than width
 * of dropdown button - hence set to 1px and use correct color
 * We set style to none by default, so you will need to override with "solid" when desired
 * which you can do by putting the dropdown in a div with following styling:
 *
 * width: 10em;
 * & div.menu {
 *   min-width: 30em !important;
 *   border-top-style: solid !important;
 * }
 */
.ui.selection.dropdown .menu {
  border-top-width: 1px !important;
	border-top-color: #4000FF !important;
	border-top-style: none;
}